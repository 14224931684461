<template>
  <BaseLayout v-slot>
    <Breadcrumbs />
    <section class="static pt-7">
      <b-container>
        <b-row>
          <b-col lg="3" class="d-none d-lg-block">
            <SidebarLinks :sidebarData="sidebarData" />
          </b-col>
          <b-col lg="9">
            <h1>{{ cmsPageContent.title }}</h1>
            <!--<div v-html="cmsPageContent.content" />-->
            <v-runtime-template
              v-if="cmsPageContent.content"
              :template="`<div>${cmsPageContent.content}</div>`"
            ></v-runtime-template>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";

import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import SidebarLinks from "@/esf_lommel_dt1_europe/core/components//SidebarLinks";
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: "StaticPage",
  components: {
    BaseLayout,
    Breadcrumbs,
    SidebarLinks,
    VRuntimeTemplate,
  },
  computed: {
    cmsPageContent() {
      return this.$store.state.cmsPage.current;
    },
  },
  mounted() {
    const bcrumb = { current: this.cmsPageContent.title, routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.cmsPageContent.meta_title },
        { name: "keywords", content: this.cmsPageContent.meta_keywords },
        { name: "description", content: this.cmsPageContent.meta_description },
      ],
      title: this.cmsPageContent.title,
    };
  },

  data: () => ({
    sidebarData: {
      heading: "Categorie",
      options: [
        { displayName: "Gentlemen", linkTo: "#", active: false },
        { displayName: "Women", linkTo: "#", active: false },
        { displayName: "Bicycle clothing sets", linkTo: "#", active: false },
        { displayName: "Children", linkTo: "#", active: false },
        { displayName: "Cycling shoes", linkTo: "#", active: false },
        { displayName: "For your bike", linkTo: "#", active: false },
        { displayName: "For your body", linkTo: "#", active: false },
        { displayName: "Brands", linkTo: "#", active: false },
        { displayName: "Cycling books", linkTo: "#", active: false },
        { displayName: "Gift voucher", linkTo: "#", active: false },
      ],
    },
  }),
};
</script>

<style lang="scss">
.alemgene-tekst {
  color: white;
}

.picture {
  float: right;
  margin-right: 30px;
  margin-top: 17px;
}

.codenameairfilter {
  color: white;
  background-color: rgb(226, 31, 33);
  height: 70px;
  border-radius: 15px;
  padding-left: 20px;
  padding-top: 20px;
}

.textairfilter {
  height: 90px;
  margin-top: -36px;
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 10px;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.teams {
  h2 {
    color: grey;
    margin-left: 10px;
    margin-top: 30px;
    margin-bottom: 25px;
    font-size: 30px;
  }
  .subtitle {
    height: 70px;
    background-color: rgb(226, 31, 33);
    color: white;
    border-radius: 15px;
    padding-left: 20px;
    padding-top: 20px;

    a {
      color: white;
      border-bottom: white 1px solid;
    }
  }
  .textbox {
    height: 170px;
    margin-top: -36px;
    padding-top: 30px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.dealer {
  h2 {
    color: grey;
    margin-left: 10px;
    margin-top: 30px;
    margin-bottom: 25px;
    font-size: 30px;
  }
  .subtitle {
    height: 70px;
    background-color: rgb(226, 31, 33);
    color: white;
    border-radius: 15px;
    padding-left: 20px;
    padding-top: 20px;

    a {
      color: white;
      border-bottom: white 1px solid;
    }
  }
  .textbox {
    height: 170px;
    margin-top: -36px;
    padding-top: 30px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-left: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

    .mail {
      border-bottom: #000 1px solid;
    }
  }
}
</style>
<style lang="scss" scoped>
h1 {
  margin-bottom: 16px;
}

ul {
  li {
    padding-left: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 1px;
      width: 4px;
      height: 4px;
      background: #000;
      border-radius: 100%;
    }
  }
}

.mb-lg-27 {
  margin-bottom: 27px;
}

.font-weight-medium {
  font-weight: 500;
}
</style>
